import { Stat, StatLabel, StatNumber,StatHelpText, StatArrow } from '@chakra-ui/react';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

export class Stats extends Component {

  render() {
    return (
        <div style={{margin: 20}}>
            <Row>
                <Col className="bg-light border" sm={{ offset: 0, size: 3 }} xs={{offset: 1, size: 5}}>
                    <Stat>
                        <div style={{textAlign:"center"}}>
                            <StatLabel>Completed Projects</StatLabel>
                            <StatNumber>
                                
                                <h3>
                                    22
                                <StatArrow type='increase'/>
                                </h3>
                                </StatNumber>
                        </div>
                    </Stat>
                </Col>
                <Col className="bg-light border"  sm={{ offset: 0, size: 3 }} xs={{offset: 1, size: 5}}>
                    <Stat>
                        <div style={{textAlign:"center"}}>
                            <StatLabel>Clients</StatLabel>
                            <StatNumber><h3>15</h3></StatNumber>
                        </div>
                    </Stat>
                </Col>
                <Col className="bg-light border"  sm={{ offset: 0, size: 3 }} xs={{offset: 1, size: 5}}>
                    <Stat>
                        <div style={{textAlign:"center"}}>
                            <StatLabel>Client Retention</StatLabel>
                            <StatNumber><h3>96%</h3></StatNumber>
                        </div>
                    </Stat>
                </Col>
                <Col className="bg-light border" sm={{ offset: 0, size: 3 }} xs={{offset: 1, size: 5}}>
                    <Stat>
                        <div style={{textAlign:"center"}}>
                            <StatLabel>Countries</StatLabel>
                            <StatNumber><span><h3>5<StatArrow type='increase'/></h3></span></StatNumber>
                        </div>
                    </Stat>
                </Col>
            </Row>
        </div>
    );
  }
}
