
import React, { Component } from 'react';
import { Col, Container, List, Row } from 'reactstrap';
import { BsCheck } from "react-icons/bs";
import { Center } from "@chakra-ui/react";
import { ServicesCard } from './ServicesCards';


export class Services extends Component{

    render(){
        return(
            <div>
                <Container>
                    <Row>
                        <Col xs={{offset: 0, size: 12}} style={{textAlign:"center"}}>
                            <h5 style={{color:"green"}}>What we do</h5>
                        </Col>
                        <Col xs={{offset: 0, size: 12}} style={{textAlign:"center"}}>
                            <h3>Our Services</h3>
                        </Col>
                    </Row>
                    <ServicesCard></ServicesCard>
                </Container>
            </div>
        );
    }
}