import React, { Component } from "react";
import collab from "../assets/collab.jpg";
import { Col, Container, List, Row } from 'reactstrap';

export class WhatWeDo extends Component{
    render(){
        return(
<div style = {{margin: 10}}>
                <Container>
                    <Row>
                        <Col className="bg-light" sm={{ offset: 0, size: 5 }} xs={{offset: 0, size: 11}} style={{margin:10}}>
                            <p style={{color:"green"}}>HOW WE DO IT</p>
                            <h2>We are industry leading <br></br> experts</h2>
                            <p></p>
                            <p>
                            As industry-leading experts in Gen AI solutions, our company
                            specializes in pioneering services that redefine the boundaries of
                            innovation.
                            With a focus on Cognitive Marketing Intelligence, we empower
                            businesses to elevate their marketing strategies through cuttingedge AI algorithms and data analytics, ensuring data-driven
                            decisions in the dynamic digital landscape.
                            Our Neural Network Security Solutions provide unparalleled
                            protection by leveraging the latest advancements in AI for proactive
                            threat detection and adaptive cybersecurity.
                            Additionally, our Conversational AI Platforms revolutionize
                            customer interactions through seamless, context-aware
                            conversations.
                            Our Augmented Data Insights for E-commerce transform online
                            retail operations with actionable analytics and predictive modeling.
                            Committed to staying at the forefront of technological
                            advancements, we deliver tailored solutions that showcase our
                            expertise in advancing businesses through the power of artificial
                            intelligence.
                            </p>
                        </Col>
                        <Col className="bg-light" sm={{ offset: 1, size: 5 }} xs={{offset: 0, size: 11}} style={{textAlign:"center",marginTop:30}}>
                            <img style={{height:500,width:490,borderRadius:20}} src={collab}></img>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}