import { Component } from "react";
import { ButtonGroup, Container, IconButton, Stack, Text } from '@chakra-ui/react'

export class Footer extends Component{
    render(){
        return(
            <Container as="footer" role="contentinfo" py={{ base: '12', md: '16' }}>
                <Stack spacing={{ base: '4', md: '5' }}>
                <Stack justify="space-between" direction="row" align="center">
                    <ButtonGroup variant="tertiary">
                    </ButtonGroup>
                </Stack>
                <Text fontSize="sm" color="fg.subtle">
                    &copy; {new Date().getFullYear()} 6<sup>th</sup>Wave Consulting
                </Text>
                </Stack>
            </Container>
        );
    }
}