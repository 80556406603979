import styles from "../styles/image.module.css";
import background from "../assets/header.png";

const ImageHeader = () => {
  return (
    <article className={styles.article}>
      <img className={styles.picture} src={background} alt="background" />
    </article>
  );
};

export default ImageHeader;