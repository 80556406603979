import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import ImageHeader from './ImageHeader';
import brand from "../assets/brand.png";
import CarouselHeader from './CarouselHeader';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-dark box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">
            <img
              alt="logo"
              src={brand}
              style={{
                height: 40,
                width: 40
              }}
            />
            <b>6<sup>th</sup>wave.ai</b>
          </NavbarBrand>
          
        </Navbar>
        <div style={ { height: 600 } }>
          <ImageHeader></ImageHeader>
        </div>
        
      </header>
    );
  }
}
