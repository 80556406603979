import React, { Component } from 'react';
import { BodySection } from './BodySection';
import ImageHeader from './ImageHeader';
import { Services } from './Services';
import { Stats } from './Stats';
import { WhatWeDo } from './WhatWeDo';
import { Footer } from './Footer';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <Stats></Stats>
        <p style={{marginTop:50}}></p>
        <BodySection></BodySection>
        <p style={{marginTop:50}}></p>
        <Services></Services>
        <p style={{marginTop:30}}></p>
        <WhatWeDo></WhatWeDo>
        <p style={{marginTop:30}}></p>
        <Footer></Footer>
      </div>
    );
  }
}
