import { Component } from "react";
import { Card, Col, Container, Row, CardBody, CardTitle, CardText } from "reactstrap";
import service1 from "../assets/services/service1.gif";
import service2 from "../assets/services/service2.gif";
import service3 from "../assets/services/service3.gif";
import service4 from "../assets/services/service4.gif";


export class ServicesCard extends Component{
    render(){
        return(
            <Container>
                <Row>
                    <Col sm={{ offset: 0, size: 3 }} xs={{offset: 0, size: 11}}>
                        <Card
                            
                            >
                                <img
                                    alt="Cognitive Marketing Intelligence"
                                    src={service1}
                                    height={50}
                                    width={50}
                                    style={{marginLeft:10,marginTop:10}}
                                />
                            <CardBody>
                                <CardTitle tag="h5">
                                    Cognitive Marketing Intelligence
                                </CardTitle>
                            </CardBody>
                            <CardBody>
                                <CardText>
                                    Harness the power of advanced artificial
                                    intelligence to elevate your marketing
                                    strategies. Our Cognitive Marketing
                                    Intelligence service line combines
                                    cutting-edge AI algorithms with deep
                                    data analysis to optimize your marketing
                                    campaigns. From predictive analytics to
                                    personalized customer engagement, we
                                    empower your brand to make datadriven decisions and stay ahead in the
                                    dynamic digital landscape.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={{ offset: 0, size: 3 }} xs={{offset: 0, size: 11}}>
                        <Card
                            
                            >
                                <img
                                    alt="Neural Network Security Solutions"
                                    src={service2}
                                    height={50}
                                    width={50}
                                    style={{marginLeft:10,marginTop:10}}
                                />
                            <CardBody>
                                <CardTitle tag="h5">
                                Neural Network Security Solutions
                                </CardTitle>
                            </CardBody>
                            <CardBody>
                                <CardText>
                                Ensure the utmost security for your
                                business with our Neural Network
                                Security Solutions. We leverage the
                                latest advancements in artificial
                                intelligence to create intelligent
                                cybersecurity systems. Our tailored
                                solutions adapt and evolve to counter
                                emerging threats, providing real-time
                                protection for your sensitive data.
                                Safeguard your digital assets with
                                proactive threat detection, automated
                                response mechanisms, and continuous
                                learning algorithms.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={{ offset: 0, size: 3 }} xs={{offset: 0, size: 11}}>
                        <Card
                            
                            >
                                <img
                                    alt="Conversational AI Platforms"
                                    src={service3}
                                    height={50}
                                    width={50}
                                    style={{marginLeft:10,marginTop:10}}
                                />
                            <CardBody>
                                <CardTitle tag="h5">
                                Conversational AI Platforms
                                </CardTitle>
                            </CardBody>
                            <CardBody>
                                <CardText>
                                    Revolutionize customer interactions with
                                    our Conversational AI Platforms. Elevate
                                    user engagement and satisfaction by
                                    integrating advanced natural language
                                    processing and machine learning into
                                    your applications. Our solutions enable
                                    seamless and context-aware
                                    conversations, whether through
                                    chatbots, virtual assistants, or voice
                                    interfaces. Enhance user experience,
                                    streamline support processes, and unlock
                                    new possibilities for your business with
                                    conversational AI.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={{ offset: 0, size: 3 }} xs={{offset: 0, size: 11}}>
                        <Card
                            
                            >
                                <img
                                    alt="Augmented Data Insights for E-commerce"
                                    src={service4}
                                    height={50}
                                    width={50}
                                    style={{marginLeft:10,marginTop:10}}
                                />
                            <CardBody>
                                <CardTitle tag="h5">
                                Augmented Data Insights for E-commerce
                                </CardTitle>
                            </CardBody>
                            <CardBody>
                                <CardText>
                                Transform your e-commerce operations
                                with Augmented Data Insights. Our AIdriven solutions go beyond traditional
                                analytics, providing actionable insights
                                that drive sales, optimize inventory, and
                                enhance the overall customer
                                experience. Leverage predictive
                                modeling, demand forecasting, and
                                personalized recommendations to stay
                                agile in the competitive e-commerce
                                landscape. Augment your decisionmaking processes with data intelligence
                                tailored to the unique challenges and
                                opportunities of online retail.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}