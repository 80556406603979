import collab from "../assets/body-section.jpg";
import React, { Component } from 'react';
import { Col, Container, List, Row } from 'reactstrap';
import { BsCheck } from "react-icons/bs";
import { Center } from "@chakra-ui/react";


export class BodySection extends Component{
    render(){
        return(
            <div style = {{margin: 10}}>
                <Container>
                    <Row>
                        <Col className="bg-light" sm={{ offset: 1, size: 5 }} xs={{offset: 0, size: 11}} style={{textAlign:"center"}}>
                            <img style={{height:350,width:420,borderRadius:20}} src={collab}></img>
                        </Col>
                        <Col className="bg-light" sm={{ offset: 0, size: 5 }} xs={{offset: 0, size: 11}} style={{margin:10}}>
                            <p style={{color:"green"}}>RESULT ORIENTED</p>
                            <h2>Safeguard your Gen AI <br></br> solutions with our <br></br>secure approach</h2>
                            <p></p>
                            <p>
                                In the fast-paced world of Generative AI, knowledge leaks<br></br>
                                threaten to undermine your hard-earned breakthroughs. At<br></br>
                                6<sup>th</sup>wave.ai, we help you <b>secure your competitive edge</b> with a<br></br>
                                <b>three-pronged approach</b>:
                            </p>
                            <p>
                                <List>
                                <li>
                                    Fortress-like architecture
                                </li>
                                <li>
                                    Continuous Vigilance
                                </li>
                                <li>
                                    Human-in-the-loop safeguards
                                </li>
                                </List>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };
}
